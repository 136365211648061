/* eslint-disable no-bitwise */

import axios from "axios";
import queryString from "query-string";

import { DEFAULT_SCOREBOARD_CONFIG } from "~/shared/constants";
import { captureError, captureMessage } from "~/shared/utils";

import store from "~/meetScoreboard/store";
import {
    setMeetId,
    setNumLanes,
    setIsUsaPrep,
    setPointsystem,
    setPointsystemTitle,
    setPointsystemDisplayName,
    setScoreboardConfig,
    setCtsScoreboardData,
    setTdrScoreboardData,
    setScoreboardVisitorsCount,
    setScoreboardTeamScoresData,
    setScoreboardEventResultsData,
    setScoreboardQrCodeData,
    setHasScore,
    setTopTeams,
    setShowMoreTeams,
    setTopSquads,
    setDualScores,
    setTopSwimmers,
    setShowMoreSwimmers,
    setTopSwims,
    setShowMoreSwims,
    setEventList,
    setShowRoundStatus,
    setNumberWidth,
    setLiveMeets,
} from "~/meetScoreboard/actions/index";

let ws;

const onMessage = (event) => {
    const content = JSON.parse(event.data);

    if (content.type === "meet.cts_scoreboard") {
        const { message } = content;

        if (message === "DISABLE_BROADCAST") {
            store.dispatch(setCtsScoreboardData(null));
            store.dispatch(setTdrScoreboardData(null));
        } else {
            store.dispatch(setCtsScoreboardData(message));
        }
    }

    if (content.type === "meet.tdr_scoreboard") {
        const { message } = content;

        if (message === "DISABLE_BROADCAST") {
            store.dispatch(setCtsScoreboardData(null));
            store.dispatch(setTdrScoreboardData(null));
        } else {
            store.dispatch(setTdrScoreboardData(message));
        }
    }

    if (content.type === "meet.scoreboard_visitors") {
        const { message } = content;

        store.dispatch(setScoreboardVisitorsCount(message));
    }

    if (content.type === "meet.scoreboard_config") {
        const { message } = content;

        store.dispatch(setScoreboardConfig(message));
    }

    if (content.type === "meet.scoreboard_team_scores_data") {
        const { message } = content;

        store.dispatch(setScoreboardTeamScoresData(message));
    }

    if (content.type === "meet.scoreboard_event_results_data") {
        const { message } = content;

        store.dispatch(setScoreboardEventResultsData(message));
    }

    if (content.type === "meet.scoreboard_qr_code_data") {
        const { message } = content;

        store.dispatch(setScoreboardQrCodeData(message));
    }

    if (content.type === "meet.update_dashboard") {
        const {
            has_score,
            top_teams,
            show_more_teams,
            top_squads,
            dual_scores,
            top_swimmers,
            show_more_swimmers,
            top_swims,
            show_more_swims,
        } = content;

        store.dispatch(setHasScore(has_score));
        store.dispatch(setTopTeams(top_teams));
        store.dispatch(setShowMoreTeams(show_more_teams));
        store.dispatch(setTopSquads(top_squads));
        store.dispatch(setDualScores(dual_scores));
        store.dispatch(setTopSwimmers(top_swimmers));
        store.dispatch(setShowMoreSwimmers(show_more_swimmers));
        store.dispatch(setTopSwims(top_swims));
        store.dispatch(setShowMoreSwims(show_more_swims));
    }

    if (content.type === "meet.update_events") {
        const { event_list, show_round_status, number_width } = content;

        store.dispatch(setEventList(event_list));
        store.dispatch(setShowRoundStatus(show_round_status));
        store.dispatch(setNumberWidth(number_width));
    }
};

const isWSOpen = () => Boolean(ws && ws.readyState === WebSocket.OPEN);

export const sendToWS = (data) => {
    if (isWSOpen()) {
        ws.send(JSON.stringify(data));
    }
};

export const changeMeet = ({ meet }) => {
    const {
        id,
        country,
        orgcode,
        num_lanes,
        pointsystem,
        pointsystem_title,
        pointsystem_display_name,
    } = meet || {};

    if (!id) return;

    const isUsaPrep = Boolean(country === "USA" && orgcode === 9);

    if (isWSOpen()) {
        ws.close();
        ws = null;
    }

    store.dispatch(setMeetId(id));
    store.dispatch(setNumLanes(num_lanes ? Number(num_lanes) : null));
    store.dispatch(setIsUsaPrep(isUsaPrep));
    store.dispatch(setPointsystem(pointsystem));
    store.dispatch(setPointsystemTitle(pointsystem_title));
    store.dispatch(setPointsystemDisplayName(pointsystem_display_name));
    store.dispatch(setCtsScoreboardData(null));
    store.dispatch(setTdrScoreboardData(null));
    store.dispatch(setScoreboardConfig(DEFAULT_SCOREBOARD_CONFIG));
    store.dispatch(setScoreboardTeamScoresData(null));
    store.dispatch(setScoreboardEventResultsData(null));
    store.dispatch(setScoreboardQrCodeData(null));
    store.dispatch(setScoreboardVisitorsCount(1));
};

export const toggleLiveMeetVisibility = async ({ isHidden }) => {
    const { meetId, liveMeets } = store.getState();

    if (!meetId) return;

    try {
        if (isHidden) {
            await axios.post(`/api/meets/${meetId}/unhide_from_live_list/`);
        } else {
            await axios.post(`/api/meets/${meetId}/hide_from_live_list/`);
        }

        const nextLiveMeets = liveMeets
            ? liveMeets.map((meet) => {
                  if (meet.id === meetId) {
                      return { ...meet, is_hidden: !isHidden };
                  }
                  return meet;
              })
            : [];

        if (isWSOpen()) {
            ws.close();
            ws = null;
        }

        store.dispatch(setLiveMeets(nextLiveMeets));
    } catch (error) {
        captureError(error);
    }
};

export const fetchCurrentScoreboardState = () => {
    sendToWS({ type: "meet.chat", message: "/scoreboard_config" });
    sendToWS({ type: "meet.chat", message: "/scoreboard_team_scores_data" });
    sendToWS({ type: "meet.chat", message: "/scoreboard_event_results_data" });
    sendToWS({ type: "meet.chat", message: "/scoreboard_qr_code_data" });
};

export const fetchLiveMeets = async () => {
    try {
        const response = await axios.get("/api/meets/live_list/");
        const { data } = response;

        store.dispatch(setLiveMeets(data || []));
    } catch (error) {
        captureError(error);
    }
};

export const init = () => {
    const {
        wsProtocol,
        meetId,
        fromPage,
        selectedGender,
        selectedAgegroup,
        selectedVarsity,
        selectedSort,
    } = store.getState();

    if (!meetId) return;

    try {
        ws = new WebSocket(
            `${wsProtocol}://${
                window.location.host
            }/ws/meet/${meetId}/?${queryString.stringify({
                from_page: fromPage,
                gender: selectedGender,
                agegroup: selectedAgegroup,
                varsity: selectedVarsity,
                sort: selectedSort,
            })}`,
        );
    } catch (error) {
        captureError(error);
        return;
    }

    ws.onopen = () => {
        fetchCurrentScoreboardState();
    };

    ws.onmessage = onMessage;

    ws.onclose = (event) => {
        if (!event.wasClean) {
            store.dispatch(setCtsScoreboardData(null));
            store.dispatch(setTdrScoreboardData(null));

            ws = null;

            if (event.code !== 1006) {
                captureMessage(
                    `Meet scoreboard WebSocket connection closed with code ${event.code}`,
                );
            }

            setTimeout(init, 5000);
        }
    };
};
