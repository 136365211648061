import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode";

import { captureError } from "~/shared/utils";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/QrCode/QrCode.less";

function QrCode({ data, hideBodyScroll = false, viewportFit = false }) {
    const { meet } = data || {};

    const [qrCodeUrl, setQrCodeUrl] = useState("");

    useEffect(() => {
        const generateQR = async () => {
            if (!meet) return;

            try {
                if (meet.full_url) {
                    const url = await QRCode.toString(meet.full_url, {
                        type: "svg",
                        scale: 4,
                        margin: 2,
                    });

                    setQrCodeUrl(url);
                }
            } catch (error) {
                captureError(error);
            }
        };

        generateQR();
    }, [meet]);

    return (
        <div
            data-scoreboard-window={hideBodyScroll ? "true" : "false"}
            className={viewportFit ? styles.pageFit : styles.page}
        >
            <h4 className={styles.title}>Scan for live results and more</h4>
            <img
                className={styles.qrCode}
                src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCodeUrl)}`}
                alt="Meet QR Code"
            />
        </div>
    );
}

QrCode.propTypes = {
    data: PropTypes.shape({}).isRequired,
    viewportFit: PropTypes.bool,
    hideBodyScroll: PropTypes.bool,
};

export default QrCode;
