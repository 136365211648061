import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HeaderLiveLabel from "~/shared/components/MeetCore/Scoreboard/Scenes/HeaderLiveLabel";
import HeaderExpandButton from "~/shared/components/MeetCore/Scoreboard/Scenes/HeaderExpandButton";

function QrCodeSheetHeader({ isExpanded }) {
    return (
        <>
            <div className="u-flex-grow1">
                <h4 className="c-scoreboard-sheet__title">QR code</h4>
                <ul
                    className={classNames({
                        "o-list-inline o-list-inline--dotted": true,
                        "u-text-small": !isExpanded,
                    })}
                >
                    <HeaderLiveLabel />
                </ul>
            </div>
            <HeaderExpandButton isExpanded={isExpanded} />
        </>
    );
}

QrCodeSheetHeader.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
};

export default QrCodeSheetHeader;
