import React from "react";
import PropTypes from "prop-types";

import BatchDisplay from "~/shared/components/MeetCore/Scoreboard/Scenes/BatchDisplay";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

const calculatePositions = (teams) => {
    let lastPosition = 0;
    let lastScore = null;

    return teams.map((team, index) => {
        let position;

        // If the score is the same as the last team, keep the same position
        if (team.score === lastScore) {
            position = lastPosition;
        } else {
            position = index + 1;
            lastPosition = position;
            lastScore = team.score;
        }

        return { ...team, position };
    });
};

function TeamScoreTable({ teamScores = [] }) {
    const rankedTeams = calculatePositions(teamScores);

    const renderTeamRow = (team) => {
        const { abbr, name, score, position } = team || {};

        return (
            <>
                <td
                    className={`${styles.cellPlace} ${styles.cellPlaceFinished}`}
                >
                    {position}
                </td>
                <td className={styles.cellPrimary}>{abbr || name}</td>
                <td className={styles.cellResult}>
                    {score ? score.toFixed(1) : "-"}
                </td>
            </>
        );
    };

    return rankedTeams ? (
        <BatchDisplay items={rankedTeams} renderItem={renderTeamRow} />
    ) : null;
}

TeamScoreTable.propTypes = {
    teamScores: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TeamScoreTable;
