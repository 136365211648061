/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import _ from "lodash";

import CtsLiveHeat from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/CtsLiveHeat";
import TdrLiveHeat from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/TdrLiveHeat";
import TeamScores from "~/shared/components/MeetCore/Scoreboard/Scenes/TeamScores/TeamScores";
import EventResults from "~/shared/components/MeetCore/Scoreboard/Scenes/EventResults/EventResults";
import QrCode from "~/shared/components/MeetCore/Scoreboard/Scenes/QrCode/QrCode";
import LiveHeatSheetHeader from "~/shared/components/MeetCore/Scoreboard/Scenes/LiveHeat/LiveHeatSheetHeader";
import TeamScoresSheetHeader from "~/shared/components/MeetCore/Scoreboard/Scenes/TeamScores/TeamScoresSheetHeader";
import EventResultsSheetHeader from "~/shared/components/MeetCore/Scoreboard/Scenes/EventResults/EventResultsSheetHeader";
import QrCodeSheetHeader from "~/shared/components/MeetCore/Scoreboard/Scenes/QrCode/QrCodeSheetHeader";
import { formatTdrClock } from "~/shared/components/MeetCore/utils";
import {
    DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY,
    TEAM_SCORES_SCOREBOARD_SCENE_ID,
    EVENT_RESULTS_SCOREBOARD_SCENE_ID,
    QR_CODE_SCOREBOAD_SCENE_ID,
} from "~/shared/constants";
import { decimalToSwim, captureError } from "~/shared/utils";

import ScoreboardSkeleton from "~/meetScoreboard/components/ScoreboadSkeleton";

import {
    init,
    sendToWS,
    toggleLiveMeetVisibility,
} from "~/meetScoreboard/actions/controller";

const THIRTY_SECONDS = 30000;

function Scoreboard({
    showEmptyMessage = false,
    meetLink = "",
    meetIsHidden = false,
}) {
    const meetId = useSelector((state) => state.meetId);
    const numLanes = useSelector((state) => state.numLanes);
    const scoreboardConfig = useSelector((state) => state.scoreboardConfig);
    const ctsScoreboardData = useSelector((state) => state.ctsScoreboardData);
    const tdrScoreboardData = useSelector((state) => state.tdrScoreboardData);
    const scoreboardVisitorsCount = useSelector(
        (state) => state.scoreboardVisitorsCount,
    );
    const scoreboardTeamScoresData = useSelector(
        (state) => state.scoreboardTeamScoresData,
    );
    const scoreboardEventResultsData = useSelector(
        (state) => state.scoreboardEventResultsData,
    );
    const scoreboardQrCodeData = useSelector(
        (state) => state.scoreboardQrCodeData,
    );
    const isScoreboardSheet = useSelector((state) => state.isScoreboardSheet);
    const hideBodyScroll = useSelector((state) => state.hideBodyScroll);
    const viewportFit = useSelector((state) => state.viewportFit);
    const showVisibilityButton = useSelector(
        (state) => state.showVisibilityButton,
    );
    const showVisitors = useSelector((state) => state.showVisitors);

    const [isReady, setIsReady] = useState(false);
    const [scoreboardMeta, setScoreboardMeta] = useState(null);
    const [ctsTimer, setCtsTimer] = useState(null);
    const [tdrTimer, setTdrTimer] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);

    const { event_name, event_heats_count, lanes_meta, is_diving, is_relay } =
        scoreboardMeta || {};
    const {
        event: ctsEventNumber,
        heat: ctsHeatNumber,
        time,
        lanes,
    } = ctsScoreboardData || {};
    const {
        startTime,
        refClockOffset,
        eventNumber: _tdrEventNumber,
        heatNumber: tdrHeatNumber,
    } = tdrScoreboardData || {};
    const { genderName } = scoreboardTeamScoresData || {};
    const { eventName, ageGroupName } = scoreboardEventResultsData || {};

    const sessionNumber =
        scoreboardConfig && scoreboardConfig.selectedFilters
            ? scoreboardConfig.selectedFilters.activeSessionNumber
            : null;

    const tdrEventNumber = _tdrEventNumber
        ? Number(_tdrEventNumber)
        : _tdrEventNumber;
    const eventNumber = ctsEventNumber || tdrEventNumber;
    const heatNumber = ctsHeatNumber || tdrHeatNumber;
    const isDiving = is_diving;

    const heatTeamDisplay =
        scoreboardConfig && scoreboardConfig.selectedFilters
            ? scoreboardConfig.selectedFilters.heatTeamDisplay
            : DEFAULT_SCOREBOARD_HEAT_TEAM_DISPLAY;

    const hideHeatSwimmer = is_relay;
    const hideResultsTeam =
        scoreboardConfig &&
        scoreboardConfig.selectedFilters &&
        !scoreboardConfig.selectedFilters.showResultsTeam;

    const hasData =
        eventNumber && heatNumber && event_name && lanes_meta && numLanes;

    const raceInProgress =
        lanes &&
        _.values(lanes).some((laneData) => {
            const laneStarted = laneData.running || laneData.time;
            const laneFinished = laneData.place || laneData.dq;
            const laneInProgress = laneStarted && !laneFinished;
            return laneInProgress;
        });

    const showTeamScores =
        scoreboardConfig &&
        scoreboardConfig.selectedScene &&
        scoreboardConfig.selectedScene.id === TEAM_SCORES_SCOREBOARD_SCENE_ID;
    const showEventResults =
        scoreboardConfig &&
        scoreboardConfig.selectedScene &&
        scoreboardConfig.selectedScene.id === EVENT_RESULTS_SCOREBOARD_SCENE_ID;
    const showQrCode =
        scoreboardConfig &&
        scoreboardConfig.selectedScene &&
        scoreboardConfig.selectedScene.id === QR_CODE_SCOREBOAD_SCENE_ID;

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const fetchScoreboardMeta = async () => {
            if (!eventNumber || !heatNumber) return;

            try {
                const response = await axios.get(
                    `/api/meets/${meetId}/scoreboard_meta/`,
                    {
                        params: {
                            session: sessionNumber,
                            event: eventNumber,
                            heat: heatNumber,
                        },
                        cancelToken: source.token,
                    },
                );

                setScoreboardMeta(response.data);
            } catch (error) {
                if (axios.isCancel(error)) return;
                captureError(error);
            }
        };

        fetchScoreboardMeta();

        return () => {
            source.cancel();
        };
    }, [sessionNumber, eventNumber, heatNumber, meetId]);

    useEffect(() => {
        function removeSkeleton() {
            const skeleton = document.getElementById(
                "scoreboard-sheet-skeleton",
            );

            if (skeleton) {
                skeleton.remove();
            }
            setShowSkeleton(false);
        }

        if (hasData) {
            removeSkeleton();
        }

        setTimeout(removeSkeleton, 5000);
    }, [hasData]);

    useEffect(() => {
        setIsReady(false);
    }, [raceInProgress]);

    useEffect(() => {
        setIsReady(true);
    }, [ctsEventNumber, ctsHeatNumber]);

    useEffect(() => {
        let ctsTimeInterval;

        if (time) {
            setCtsTimer(time);
            clearInterval(ctsTimeInterval);

            ctsTimeInterval = setInterval(() => {
                setCtsTimer((prev) => Math.round((prev + 0.1) * 10) / 10);
            }, 100);
        }

        return () => clearInterval(ctsTimeInterval);
    }, [time]);

    useEffect(() => {
        if (startTime && startTime !== 0) {
            const interval = setInterval(() => {
                setTdrTimer(
                    formatTdrClock(Date.now() - startTime + refClockOffset),
                );
            }, 100);

            return () => clearInterval(interval);
        }

        return () => setTdrTimer(null);
    }, [startTime, refClockOffset]);

    useEffect(() => {
        const interval = setInterval(() => {
            sendToWS({ type: "meet.chat", message: "/ping" });
        }, THIRTY_SECONDS);

        return () => clearInterval(interval);
    }, []);

    function handleExpandCollapse() {
        setIsExpanded((prevState) => !prevState);
    }

    const onToggleMeetVisibility = async () => {
        setIsSubmitting(true);
        await toggleLiveMeetVisibility({ isHidden: meetIsHidden });
        setIsSubmitting(false);
    };

    function renderScoreboardSheet() {
        const timer =
            raceInProgress && !isDiving && Boolean(ctsTimer)
                ? decimalToSwim(ctsTimer)
                : "";
        let header = (
            <LiveHeatSheetHeader
                eventName={event_name}
                eventNumber={eventNumber}
                heatNumber={heatNumber}
                eventHeatsCount={event_heats_count}
                timer={timer}
                isDiving={isDiving}
                isExpanded={isExpanded}
            />
        );
        let content = (
            <CtsLiveHeat
                raceInProgress={raceInProgress}
                splashByLane={lanes_meta}
                lanes={lanes}
                timer={ctsTimer}
                numLanes={numLanes}
                eventName={event_name}
                eventNumber={eventNumber}
                eventHeatsCount={event_heats_count}
                heat={heatNumber}
                isReady={isReady}
                isDivingEvent={isDiving}
                teamDisplay={heatTeamDisplay}
                hideSwimmer={hideHeatSwimmer}
                hideHeader={isExpanded}
                hideBodyScroll={hideBodyScroll}
                viewportFit={viewportFit}
                showTimer={!isExpanded}
            />
        );

        if (tdrScoreboardData) {
            header = (
                <LiveHeatSheetHeader
                    eventName={event_name}
                    eventNumber={eventNumber}
                    heatNumber={heatNumber}
                    eventHeatsCount={event_heats_count}
                    timer={tdrTimer}
                    isDiving={isDiving}
                    isExpanded={isExpanded}
                />
            );
            content = (
                <TdrLiveHeat
                    scoreboardData={tdrScoreboardData}
                    splashByLane={lanes_meta}
                    numLanes={numLanes}
                    eventName={event_name}
                    eventNumber={eventNumber}
                    eventHeatsCount={event_heats_count}
                    isDivingEvent={isDiving}
                    heatNumber={heatNumber}
                    teamDisplay={heatTeamDisplay}
                    hideSwimmer={hideHeatSwimmer}
                    hideHeader={isExpanded}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                />
            );
        }

        if (showTeamScores) {
            header = (
                <TeamScoresSheetHeader
                    genderName={genderName}
                    isExpanded={isExpanded}
                />
            );
            content = (
                <TeamScores
                    teamScoresData={scoreboardTeamScoresData}
                    hideHeader={isExpanded}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                />
            );
        }

        if (showEventResults) {
            header = (
                <EventResultsSheetHeader
                    eventName={eventName}
                    ageGroupName={ageGroupName}
                    isExpanded={isExpanded}
                />
            );
            content = (
                <EventResults
                    eventResults={scoreboardEventResultsData}
                    hideTeam={hideResultsTeam}
                    hideHeader={isExpanded}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                />
            );
        }

        if (showQrCode) {
            header = <QrCodeSheetHeader isExpanded={isExpanded} />;
            content = <QrCode data={scoreboardQrCodeData} />;
        }

        return (
            <div
                data-scoreboard-expanded={isExpanded}
                className={classNames({
                    "c-scoreboard-sheet": true,
                    "c-scoreboard-sheet--expanded": isExpanded,
                })}
            >
                <div
                    className="c-scoreboard-sheet__header"
                    role="button"
                    tabIndex={0}
                    onClick={handleExpandCollapse}
                >
                    {header}
                </div>
                {isExpanded && (
                    <div className="c-scoreboard-sheet__body">{content}</div>
                )}
            </div>
        );
    }

    function renderScoreboard() {
        if (showTeamScores) {
            return (
                <TeamScores
                    teamScoresData={scoreboardTeamScoresData}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                    tableFontSize="2.9vw"
                />
            );
        }

        if (showEventResults) {
            return (
                <EventResults
                    eventResults={scoreboardEventResultsData}
                    hideTeam={hideResultsTeam}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                    tableFontSize="2.9vw"
                />
            );
        }

        if (showQrCode) {
            return (
                <QrCode
                    data={scoreboardQrCodeData}
                    viewportFit={viewportFit}
                    hideBodyScroll={hideBodyScroll}
                />
            );
        }

        if (tdrScoreboardData) {
            return (
                <TdrLiveHeat
                    scoreboardData={tdrScoreboardData}
                    splashByLane={lanes_meta}
                    numLanes={numLanes}
                    eventName={event_name}
                    eventNumber={eventNumber}
                    eventHeatsCount={event_heats_count}
                    timer={tdrTimer}
                    isDivingEvent={isDiving}
                    heatNumber={heatNumber}
                    teamDisplay={heatTeamDisplay}
                    hideSwimmer={hideHeatSwimmer}
                    hideBodyScroll={hideBodyScroll}
                    viewportFit={viewportFit}
                />
            );
        }

        return (
            <CtsLiveHeat
                raceInProgress={raceInProgress}
                splashByLane={lanes_meta}
                lanes={lanes}
                timer={ctsTimer}
                numLanes={numLanes}
                eventName={event_name}
                eventNumber={eventNumber}
                eventHeatsCount={event_heats_count}
                heat={heatNumber}
                isReady={isReady}
                isDivingEvent={isDiving}
                teamDisplay={heatTeamDisplay}
                hideSwimmer={hideHeatSwimmer}
                hideBodyScroll={hideBodyScroll}
                viewportFit={viewportFit}
                showTimer
            />
        );
    }

    function renderVisibilityButton() {
        const actionName = meetIsHidden ? "Show" : "Hide";

        return (
            <button
                type="button"
                className="btn btn-icon-plain btn-icon-danger u-color-danger"
                title={`${actionName} meet`}
                disabled={isSubmitting}
                onClick={onToggleMeetVisibility}
            >
                <i
                    className={classNames({
                        "fa-regular": true,
                        "fa-eye": meetIsHidden,
                        "fa-eye-slash": !meetIsHidden,
                        "u-mr-": true,
                    })}
                />
                {actionName}
            </button>
        );
    }

    function renderFooter() {
        if (!showVisibilityButton && !showVisitors && !meetLink) return null;

        const visibilityButton = renderVisibilityButton();
        const content = (
            <div
                className={classNames({
                    "u-pull-end": !showVisibilityButton,
                    "u-text-small u-color-mute-invert": true,
                })}
            >
                <ul className="o-list-inline">
                    {showVisitors ? (
                        <li className="u-color-danger">
                            <i className="fa-regular fa-user u-mr--" />{" "}
                            {scoreboardVisitorsCount}
                        </li>
                    ) : null}
                    {meetLink ? (
                        <li className="u-ml+">
                            <a
                                className="c-more u-link-inverted u-pv u-pr+"
                                href={meetLink}
                            >
                                Go to meet
                            </a>
                        </li>
                    ) : null}
                </ul>
            </div>
        );

        if (showVisibilityButton && (showVisitors || meetLink)) {
            return (
                <div className="u-flex u-flex-align-items-center u-flex-justify-between">
                    {visibilityButton}
                    {content}
                </div>
            );
        }

        return content;
    }

    function renderEmptyMessage() {
        return (
            <div className="c-blankslate c-blankslate--small c-blankslate--clean u-background-transparent">
                <h4 className="c-blankslate__title u-color-mute-invert">
                    Scoreboard is not currently available
                </h4>
                <ul className="o-list-inline">
                    {showVisibilityButton ? (
                        <li className="u-color-danger">
                            {renderVisibilityButton()}
                        </li>
                    ) : null}
                    <li
                        className={classNames({
                            "u-ml+": showVisibilityButton,
                        })}
                    >
                        <a
                            className={classNames({
                                btn: showVisibilityButton,
                                "c-more u-link-inverted": true,
                            })}
                            href={meetLink}
                        >
                            Go to meet
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    if (!hasData) {
        if (showEmptyMessage) {
            return showSkeleton ? <ScoreboardSkeleton /> : renderEmptyMessage();
        }

        return null;
    }

    return (
        <>
            {isScoreboardSheet ? renderScoreboardSheet() : renderScoreboard()}
            {renderFooter()}
        </>
    );
}

Scoreboard.propTypes = {
    showEmptyMessage: PropTypes.bool,
    meetLink: PropTypes.string,
    meetIsHidden: PropTypes.bool,
};

export default Scoreboard;
