import { setter } from "~/shared/setter";

export const setMeetId = setter("meetId");

export const setNumLanes = setter("numLanes");

export const setIsUsaPrep = setter("isUsaPrep");

export const setPointsystem = setter("pointsystem");

export const setPointsystemTitle = setter("pointsystemTitle");

export const setPointsystemDisplayName = setter("pointsystemDisplayName");

export const setScoreboardConfig = setter("scoreboardConfig");

export const setCtsScoreboardData = setter("ctsScoreboardData");

export const setTdrScoreboardData = setter("tdrScoreboardData");

export const setScoreboardVisitorsCount = setter("scoreboardVisitorsCount");

export const setScoreboardTeamScoresData = setter("scoreboardTeamScoresData");

export const setScoreboardEventResultsData = setter(
    "scoreboardEventResultsData",
);

export const setScoreboardQrCodeData = setter("scoreboardQrCodeData");

export const setHasScore = setter("hasScore");

export const setTopTeams = setter("topTeams");

export const setShowMoreTeams = setter("showMoreTeams");

export const setTopSquads = setter("topSquads");

export const setDualScores = setter("dualScores");

export const setTopSwimmers = setter("topSwimmers");

export const setShowMoreSwimmers = setter("showMoreSwimmers");

export const setTopSwims = setter("topSwims");

export const setShowMoreSwims = setter("showMoreSwims");

export const setEventList = setter("eventList");

export const setShowRoundStatus = setter("showRoundStatus");

export const setNumberWidth = setter("numberWidth");

export const setLiveMeets = setter("liveMeets");
