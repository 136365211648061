import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import objectHash from "object-hash";
import { AnimatePresence, motion } from "motion/react";

import {
    SCOREBOARD_MOTION_TBODY_VARIANTS,
    SCOREBOARD_MOTION_TR_VARIANTS,
} from "~/shared/constants";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

const ITEMS_PER_BATCH = 8;

function BatchDisplay({ items, renderItem }) {
    const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
    const totalBatches = Math.max(1, Math.ceil(items.length / ITEMS_PER_BATCH));
    const itemsHash = objectHash(items);

    useEffect(() => {
        setCurrentBatchIndex(0);
        const interval = setInterval(() => {
            setCurrentBatchIndex((prevIndex) => (prevIndex + 1) % totalBatches);
        }, 12000);

        return () => clearInterval(interval);
    }, [itemsHash, totalBatches]);

    const currentBatch =
        items.length > 0
            ? items.slice(
                  currentBatchIndex * ITEMS_PER_BATCH,
                  (currentBatchIndex + 1) * ITEMS_PER_BATCH,
              )
            : [];

    return (
        <motion.table className={`table ${styles.table}`}>
            <AnimatePresence mode="wait">
                <motion.tbody
                    key={`${itemsHash}-${currentBatchIndex}`}
                    variants={SCOREBOARD_MOTION_TBODY_VARIANTS}
                    initial="initial"
                    animate="show"
                    exit="exit"
                >
                    {currentBatch.map((item) => (
                        <motion.tr
                            key={item.id}
                            variants={SCOREBOARD_MOTION_TR_VARIANTS}
                        >
                            {renderItem(item)}
                        </motion.tr>
                    ))}
                </motion.tbody>
            </AnimatePresence>
        </motion.table>
    );
}

BatchDisplay.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    renderItem: PropTypes.func.isRequired,
};

export default BatchDisplay;
